import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Box } from 'grommet';

import Loading from '../components/Loading';

const Cart = () => {
  useEffect(() => {
    navigate('/', {
      state: { cartOpen: true },
    });
  }, []);

  return (
    <Box pad="large" align="center" justify="center" fill>
      <Loading />
    </Box>
  );
};

Cart.propTypes = {
  location: PropTypes.object,
};

export default Cart;
